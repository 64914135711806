:root { 
--black_900_3a:#0000003a; 
--black_900_7e:#0000007e; 
--red_200:#e7ad99; 
--black_900_90:#00000090; 
--red_300:#cf796c; 
--red_200_1b:#e7ad991b; 
--black_900_3f:#0000003f; 
--gray_300_33:#e1e1e133; 
--gray_300_4c:#dcdcdc4c; 
--black_900_66:#00000066; 
--black_900:#000000; 
--red_300_6c_01:#cf796c6c; 
--red_300_1b:#c08c5d1b; 
--blue_gray_700:#485867; 
--deep_orange_100:#ecc8ae; 
--black_900_26:#00000026; 
--gray_600:#828282; 
--black_900_4c:#0000004c; 
--gray_700:#5a5a5a; 
--gray_400:#d1b5b1; 
--black_900_a2:#000000a2; 
--white_A700_6c:#ffffff6c; 
--blue_gray_100:#d9d9d9; 
--red_200_19:#e7ad9919; 
--black_900_8e:#0000008e; 
--black_900_6c:#0000006c; 
--white_A700_a2:#ffffffa2; 
--red_300_01:#c08c5d; 
--gray_900:#300e00; 
--gray_300:#dddddd; 
--gray_300_24:#e1e1e124; 
--black_900_2d:#0000002d; 
--gray_100:#f3f3f3; 
--red_200_6c:#e7ad996c; 
--black_900_75:#00000075; 
--blue_gray_700_5e:#4858675e; 
--black_900_33:#00000033; 
--red_300_a2:#cf796ca2; 
--deep_orange_100_2d:#ecc8ae2d; 
--black_900_19:#00000019; 
--red_300_6c:#c08c5d6c; 
--white_A700:#ffffff; 
--red_300_19:#c08c5d19; 
--deep_orange_100_4c:#ecc8ae4c; 
}